/**
 * Module to make using the backend easier.
 * @module
 */

import GetConfig from '../Config.js';

/** 
 * @function
 * @async
 * @description
 * A wrapper for fetch to reach the backend api.
 * This wrapper prepends GetConfig().SERVER_ADDRESS to the endpoint and sets the following default values for any request:
 * 
 * {
 * 
 *  method: "GET"
 * 
 *  crossDomain: true
 * 
 *  headers: {
 * 
 *      "Authorization": "Bearer <the current access token>"
 *
 *      "Content-Type": "application/json",
 * 
 *      "Accept": "application/json",
 * 
 *      "Access-Control-Allow-Origin": GetConfig().SERVER_ADDRESS,
 *  }
 * 
 * }
 * 
 * Any request made using with this function can omit these parameters in the request.
 * To override any of the values simply provide them in the req argument.
 * @param {string} endpoint the endpoint of the controller you are requesting
 * @param {RequestInit} [req={method: "GET",crossDomain:true,headers: {"Authorization": "Bearer " + window.localStorage.getItem("token"),"Content-Type":"application/json",Accept:"application/json","Access-Control-Allow-Origin":GetConfig().SERVER_ADDRESS,}}] a request object. Providing this will override the defaults above for the fields
 * @returns {Promise<Response>} the promise generated by the underlying fetch request
*/
async function apiRequest(endpoint, req={}) {
    try {
        const serverAddress = GetConfig().SERVER_ADDRESS;
        const defaultHeaders = {
            method: "GET",
            crossDomain: true,
            headers: {
                "Authorization": "Bearer " + window.localStorage.getItem("token"),
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": serverAddress,
            }
        };

        console.debug("API Request Debug:");
        console.debug("Server Address:", serverAddress);
        console.debug("Endpoint:", endpoint);
        console.debug("Default Headers:", defaultHeaders);
        console.debug("Provided Request Options:", req);

        const requestOptions = {
            ...defaultHeaders,
            ...req
        };

        console.debug("Final Request Options:", requestOptions);

        const response = await fetch(serverAddress + endpoint, requestOptions);
        
        console.debug("Response Status:", response.status);
        console.debug("Response Headers:", response.headers);
        
        if (!response.ok) {
            console.error("API Request failed with status:", response.status);
            throw new Error(`API request failed: ${response.statusText}`);
        }

        return response;
    } catch (error) {
        console.error("Error during API request:", error);
        throw error;
    }
}

export default apiRequest;
