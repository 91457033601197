//To change the address of the machine hosting the backend, modify below.
// e.g. "SERVER_ADDRESS": "http://127.0.0.1:5000", where 127.0.0.1 is the public IPv4 of the hosting machine. 

function GetConfig() {
    return {
        "SERVER_ADDRESS": "http://44.212.209.119:5000"
    };
}

export default GetConfig;
